

/**
 * @see https://material-ui.com/components/typography/#typography
 * @see https://github.com/DecliningLotus/fontsource
 * @see https://github.com/DecliningLotus/fontsource/blob/master/packages/roboto/README.md
 */
@import '~fontsource-roboto/index.css';

// @see https://reactgo.com/add-fonts-to-react-app/
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}

audio { width: 100%; }
audio:focus { outline: none; }

// @see https://material-ui.com/customization/default-theme
